import React from "react"

import { Row } from "antd"
import SEO from "../components/shared/seo"

interface NotFoundPageProps {
  content: string
}

const NotFoundPage: React.FC<NotFoundPageProps> = (props) => {
  const { content } = props

  return (
    <>
      <SEO title='404' />
      <Row
        role="flex"
        justify="center"
        style={{ top: "10vh", bottom: 0, position: "relative" }}
      >
        <h1>{content || 'Page Not Found'}</h1>
      </Row>
    </>
  )
}

export default NotFoundPage
